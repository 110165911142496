<script lang="ts" setup>
const { t } = useI18n();
const route = useRoute();

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
});
const title = computed(() => (route.meta.title ? t(route.meta.title) : "Homerunner"));
const orientation = ref("portrait-primary");

//setup orientationchange event listener
onMounted(() => {
  if (shouldListenToOrientation.value) {
    screen.orientation.addEventListener("change", (event) => {
      orientation.value = event.target.type;
    });

    //set initial orientation
    orientation.value = screen.orientation.type;
  }
});

onUnmounted(() => {
  if (shouldListenToOrientation.value)
    screen.orientation.addEventListener("change", (event) => {
      orientation.value = event.target.type;
    });
});

const shouldListenToOrientation = computed(() => {
  if (process.client) {
    //check if the device is a mobile device via user agent
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  return false;
});
</script>

<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <Title>{{ title }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <slot />
    </Body>
  </Html>
</template>
